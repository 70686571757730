/**
 * theme.scss
 * =============================================================================
 * Entrypoint for all theme styles
 */

// Custom variables and Bootstrap overrides
@import "variables";

// Custom SCSS mixins
@import "mixins";

// Custom SCSS functions
@import "functions"; 

// Custom fonts (unless using Typekit / Google Fonts etc.)
@import "fonts";

// Minimal Boostrap required for customisation
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// Custom variables on top of Bootstrap defined ones...
$custom-colors: (
  "my-custom-colour": red
);

// $theme-colors: map-merge($theme-colors, $custom-colors);
// $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// $utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
// $utilities-text: map-merge($utilities-text, $utilities-colors);
// $utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text");
// $utilities-bg: map-merge($utilities-bg, $utilities-colors);
// $utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg");

// Bootstrap utilities
@import "bootstrap/scss/maps";
@import "bootstrap/scss/utilities";

// Custom utilities on top of Bootstrap defined ones...
$utilities: map-merge(
  $utilities,
  (
    "font-size": map-merge(
      map-get($utilities, "font-size"),
      (
        responsive: true
      )
    ),
    // "rounded": map-merge(
    //   map-get($utilities, "rounded"),
    //   (
    //     responsive: true,
    //     values: map-merge(
    //       map-get(map-get($utilities, "rounded"), "values"),
    //       (
    //         4: .5rem,
    //         5: 1rem
    //       )
    //     ),
    //   ),
    // ),
    // "background-color": map-merge(
    //   map-get($utilities, "background-color"),
    //   (
    //     values: map-merge(
    //       map-get(map-get($utilities, "background-color"), "values"),
    //       (
    //         "primary-transparent": rgba($primary, 0.5),
    //         "secondary-transparent": rgba($secondary, 0.5),
    //         "light-transparent": rgba($light, 0.5),
    //         "dark-transparent": rgba($dark, 0.5)
    //       )
    //     ),
    //   ),
    // ),
  )
);

// Vendor imports
@import "bootstrap/scss/bootstrap";
// @import '/node_modules/swiper/swiper-bundle.css'; // Because it's CSS we need the full path
// @import '/node_modules/aos/dist/aos.css'; // Because it's CSS we need the full path
@import '/node_modules/tom-select/dist/css/tom-select.bootstrap5.css'; // Because it's CSS we need the full path
// @import '/node_modules/photoswipe/dist/photoswipe.css'; // Because it's CSS we need the full path

// Vendor additions (e.g. for styling apps, or other vendor JS
@import 'vendor-additions';

// Core Stencil-based build SCSS
@import "core";

// Theme / client-specific SCSS
@import "custom";
