/**
 * core.scss
 * =============================================================================
 * Core Stencil SCSS. This should not be edited. If something here needs to
 * change it should be turned into a variable and passed in from variables.scss
 */

// /**
//  * Additional CSS variables
//  */
// :root {
//   @each $name, $size in $grid-breakpoints {
//     --#{$variable-prefix}breakpoint-#{$name}: #{$size};
//   }
// }

/**
 * Responsive Images (see image.liquid)
 */
.image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%; // This should be set dynamically on the wrapper element in Liquid: {{ 1 | divided_by: image_desktop.aspect_ratio | times: 100.0 }}%
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.opt-contrast {
    image-rendering: auto; 
  }
}
// Fade in lazy-loaded images once the 'loaded' or 'lazyloaded' class is present
.image-fade[data-src] {
  opacity: 0;
  &.loaded, &.lazyloaded {
    animation: fadeIn 1s forwards;
  }
}
// Fade in eager-loaded images, with onload trigger changing opacity to 1
.image-fade-eager {
  opacity: 0;
  transition: all 1s ease; 
}
.image-cover, .media-cover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.image-wrapper-v2 {
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: block;
  }

  > img {
    height: auto;
  }
  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.image-cover-v2, .media-cover-v2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// /**
//  * Navigation
//  */
// @if $navbar-autohide {
//   .scrolled-down {
//     opacity: 0;
//     transition: all 0.2s ease-in-out;
//     pointer-events: none;
//   }
//   .scrolled-up {
//     opacity: 1;
//     transition: all 0.2s ease-in-out;
//   }
// }
// .dropdown-menu.show {
//   animation: fadeIn 0.4s;
// }

// /**
//  * Typography
//  */ 
// .font-heading {
//   font-family: $headings-font-family;
// }
// p:last-child {
//   margin-bottom: 0;
// }
// .text-oblique {
//   background-size: 100% 50%;
//   background-repeat: no-repeat;
//   background-position: center;
//   pointer-events: none;
//   opacity: 0.4;

//   background-image: linear-gradient(to top left,
//     rgba(0,0,0,0) 0%,
    // rgba(0,0,0,0) calc(50% - 0.8px),
    // rgba(0,0,0,1) 50%,
    // rgba(0,0,0,0) calc(50% + 0.8px),
    // rgba(0,0,0,0) 100%);
// }
// .text-truncate-ellipsis {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // max-width: 100%;
  // white-space: nowrap;
// }

// /**
 // * Animations
 // */
// @keyframes fadeIn {
  // 0% { opacity: 0; }
  // 100% { opacity: 1; }
// }

// @keyframes fadeOut {
//   0% { opacity: 1; }
//   100% { opacity: 0; display: none; }
// }

// @keyframes gradient {
//   0% { background-position: 0% 50%; }
//   50% { background-position: 100% 50%; }
//   100% { background-position: 0% 50%; }
// }

// @keyframes grey-fade {
//   0%{
//     background-color: var(--bs-light);
//   }
//   50%{
//     background-color: var(--bs-gray-200);
//   }
//   100%{
//     background-color: var(--bs-light);
//   }
// }

// .fade-in {
//   animation: fadeIn 0.4s forwards;
// }

// .fade-out {
//   animation: fadeOut 0.4s forwards;
// }

// .bg-gradient-animate {
//   background: linear-gradient(-45deg, $primary, #5996D4);
//   background-size: 400% 400%;
//   animation: gradient 5s ease infinite; 
// }

// .animated-image-placeholder {
//   animation: grey-fade 3s infinite ease-in-out;
// }

// /**
//  * JS Interactions
//  */
// .collapse.no-collapse-md {
//   @include media-breakpoint-up(md) {
//     display: block !important;
//   }
// }
// .collapse.no-collapse-lg {
//   @include media-breakpoint-up(lg) {
//     display: block !important;
//   }
// }

// /**
//  * Product
//  */
// #product-sticky-buy {
//   @extend .translate-y-100;
//   transition: transform 0.4s ease-in-out;
// }

// /**
//  * Cart
//  */
//  [data-cart-item-count] {
//   width: 15px;
//   height: 15px;
//   top: 10px;
//   right: -7px;
// }
// [data-cart-item-count='0'] {
//   display: none !important;
// }

// // Oh lord. This is a series of hacks to get round the fact that Chrome, Firefox
// // and Safari all have different ways to force a fixed / fluid / fixed column
// // (i.e. header, fluid content, footer) where the fluid content fills the height
// // of the screen. Note 100vh on Safari means INCLUDING the URL bar - which isn't
// // always visible.
// .shopify-section.cart-offcanvas {
//   height: 100%; // Firefox
//   height: -webkit-fill-available; // Chrome, Safari
// }
// #cart-offcanvas-content {
//   @supports (-webkit-touch-callout: none) {
//     /* CSS specific to iOS devices */
//     height: -webkit-fill-available; // Safari only
//   }

//   @supports not (-webkit-touch-callout: none) {
//     /* CSS for other than iOS devices */ 
//     height: 100%; // Chrome, Firefox
//   }
// }

// /**
//  * Flashtags
//  */
//  .flashtag {
//   opacity: 0;
//   display: inline-block;
//   white-space: nowrap;
//   background-color: black;
//   color: white;
//   @extend .p-2;
// }

// /**
//  * Swatches
//  */
// .swatch {
//   background-repeat: no-repeat;
//   background-position: center;
//   width: $swatch-width;
//   height: $swatch-height;
// }

// /**
//  * Miscellaneous
//  */
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}
.z-index-6 {
  z-index: 6;
}

// .cursor-pointer {
//   cursor: pointer;
// }
// .loading span {
//   visibility: hidden;
// }
// .h-50vh {
//   height: 50vh;
// }
// .h-25vh {
//   height: 25vh;
// }
// #alert-container.show {
//   z-index: 1050;
// }
// .translate-y-0 {
//   transform: translateY(0) !important;
// }
// .translate-y-100 {
//   transform: translateY(100%);
// }

// // Useful for overlays
// .position-cover {
//   left: 0;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   position: absolute;
// }

// /**
//  * Many fonts are designed to handle accents & diacriticals, but it results 
//  * in the line height adding 'additional space' which isn't necessary in
//  * English. 80% is a good average to remove the space for these characters (e.g.
//  * 'ζ̌' and 'Ê'). See https://stackoverflow.com/a/49184417/305070 for more info.
//  *
//  * Of course, this causes multiline text to appear to close together, 
//  * particualarly if it's all caps. So use with caution!
//  */
// .lh-80 {
//   line-height: 80%;
// }
