/**
 * variables.scss
 * =============================================================================
 * Any variables or Bootstrap overrides
 */

$swatch-width: 30px;
$swatch-height: 30px;

$navbar-autohide: true;

$enable-negative-margins: true;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1600px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1920px
);

// Add in additional space (margin / padding) sizes - more than the default 5
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 9,
);

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.125;
$h6-font-size:                $font-size-base;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-base * 0.75,
  8: $font-size-base * 0.675,
  9: $font-size-base * 0.5
);

$aspect-ratios: (
  "1x1": 100%,
  "2x3": calc(3 / 2 * 100%),
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "9x16": calc(16 / 9 * 100%)
);


$headings-font-family: quatro, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: quatro, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-weight-base: 300;
$line-height-base: 1.6;

$headings-line-height: 1.2;
$headings-font-weight: 400;

$headings-margin-bottom: $spacer * 1.5;


// $nav-link-color: #444;
$nav-link-font-weight: 500;
$nav-link-padding-x: 18px;


$primary: #444;
$secondary: #635496;
$text-muted: #8b8b8b;
$danger: #742567;


$link-decoration: none;
$link-color: #635496;
$link-hover-color: #635496;
$link-hover-decoration: underline;

// Colors
// $mainColour: #635496;
// $mainDarkColour:#444;
// $backgroundColour: #fff;
// $grey:#8b8b8b;
// $color1: #742567;
// $color2:#E0E0E0;
// $color3: #565656;
// $color4: #333333;
//
$spinner-animation-speed: 1s;
$badge-font-size: .9em;

$accordion-padding-x: 0;
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-color: black;
$accordion-icon-color: black;
$accordion-icon-active-color: black;
$accordion-icon-transform: translate(0, 50%);
$accordion-button-active-bg: transparent;
$accordion-button-active-color: black;
$accordion-button-focus-box-shadow: none;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16.27' height='16.27' viewBox='0 0 16.27 16.27'><defs><style>.a{fill:none;stroke:#442530;}</style></defs><g transform='translate(183.111 -715.141) rotate(45)'><g transform='translate(381.954 629.408)'><line class='a' x2='16.27' transform='translate(0 0) rotate(45)'/><line class='a' x2='16.27' transform='translate(0 11.504) rotate(-45)'/></g></g></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16.27' height='1' viewBox='0 0 16.27 1'><defs><style>.a{fill:none;stroke:#442530;}</style></defs><line class='a' x2='16.27' transform='translate(0 0.5)'/></svg>");
$accordion-body-padding-y: 2rem;
$accordion-button-padding-y: 1.5rem;

$modal-content-border-radius: 0;
$carousel-transition-duration: 0;
$carousel-indicator-transition: 0;

$nav-link-font-weight: 400;

$navbar-light-color: $primary;
$navbar-light-hover-color: $secondary;
$navbar-light-active-color: $primary;

$navbar-dark-color: white;
$navbar-dark-hover-color: $secondary;
$navbar-dark-active-color: $secondary;

$navbar-nav-link-padding-x: 1rem;

$breadcrumb-active-color: $primary;