/*** General styling ***/
.header-dark {
  margin: 0;
  padding: 0;
  background-color: #8b8b8b;
  background-image: url(https://a.storyblok.com/f/253271/2000x354/02d7c54a1c/bg-footer.jpg);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left top;
}

.header-light {
  background-color: #fff;
}

.header-logo {
    max-width:250px;
}
.header {
  .search-input {
    &:focus-visible {
      outline: none !important;
    }
  }
}

button{
  border:none;
  outline:none;
}

.mp-btn, .btn {
  display: inline-block;
  padding:10px 25px;
  color:#444;
  text-decoration: none!important;
  text-transform: uppercase;
  overflow: hidden;
  background-color:transparent;
  border:none;
  /*@include transition-property(color);
  @include transition-duration(0.333s);
  @include transition-timing-function(in-and-out);*/
  position:relative;
  color:$secondary;
  box-shadow: none;

  &.mp-white {
    background-image: url(https://a.storyblok.com/f/253271/300x120/86eb412d7f/bg-btn-top-r-white.png), url(https://a.storyblok.com/f/253271/300x120/7108017a04/bg-btn-bottom-l-white.png);
    color:#fff;
    &:hover, &:active, &:focus {
      color:#fff;
    }
  }
  &:hover, &:active, &:focus {
    text-decoration: none;
    color:$primary;
    background-color:transparent;
    border:none;
    box-shadow: none;
    //background-image: url(/images/layout/bg-btn-top-r.png), url(/images/layout/bg-btn-bottom-l.png);
  }
  background-image: url(https://a.storyblok.com/f/253271/300x120/0cad042d04/bg-btn-top-r.png), url(https://a.storyblok.com/f/253271/300x120/4fd9c683ef/bg-btn-bottom-l.png);
  background-repeat: no-repeat, no-repeat;
  background-size: auto auto;
  background-position: top right, bottom left;
  &:before {

  }
  &:after {

  }
}

// .featured-illusions {
//     background: url('https://a.storyblok.com/f/253271/2000x579/f63fc4f452/home-banner-bg.jpg');
//     background-size: cover;
//     background-position: center;

//     .featured-illusions-title {
//         line-height:1.2;
//         font-size:2em;
//         margin-bottom:0.4em;
//     }
//     .featured-illusions-content {

//     }
// }

.align-image-center {
    width: 100%;
    display: block;
    text-align: center;
}


.position-cover{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.bg-gray-80{
    background-color: rgba(139, 139, 139, 0.8);
}

.bg-secondary-80{
    background-color: rgba(99, 84, 150, 0.8);
}

.hover-overlay {
  .hide-on-hover, .show-on-hover {
    transition: opacity 0.2s ease-in-out;
  }

  .hide-on-hover {
    opacity: 1;
  }

  .show-on-hover {
    opacity: 0;
  }

  /* Only on devices that support a 'hover' mechanism (e.g. desktops, laptops) */
  @media (hover: hover) {
    &:hover {
      .hide-on-hover {
        opacity: 0;
      }
      .show-on-hover {
        opacity: 1;
      }
    }
  }
}

.callout-image{
    min-height:200px;
    height:100%;
    .callout-content{
        padding:40px;
        // img {
        //     width:50%;
        //     margin-bottom:24px;
        // }
    }
}

.footer-logo{
    max-width:150px;
}

.caption-font{
  font-size: 1.4rem;
}

/**
 * Typography
 */
p:last-of-type {
  margin-bottom: 0;
}
a.no-underline, a.no-underline:hover {
  text-decoration: none;
}

/**
 * Nav
 */
.nav-link {
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    margin: 5px 0 0;
    background: $secondary;
    transition: width .3s;
  }
  &:hover::after {
    width: 100%;
  }
}

/**
 * Motifs
 */
.stamp {
  width: 100%;
  @include media-breakpoint-up(md) {
    left: 33.4%; 
    width: 33.2%
  }
}

.dogear-top-end {
  --dogear-size: 30px;
  clip-path: polygon(0% 0%, calc(100% - var(--dogear-size)) 0%, 100% var(--dogear-size), 100% 100%, 0% 100%);
}
.dogear-bottom-start {
  --dogear-size: 30px;
  clip-path: polygon(100% 100%, var(--dogear-size) 100%, 0% calc(100% - var(--dogear-size)), 0% 0%, 100% 0%);
}

.dogear-top-end.dogear-bottom-start {
  --dogear-size: 30px;
  clip-path: polygon(var(--dogear-size) 100%, 0% calc(100% - var(--dogear-size)), 0% 0%, calc(100% - var(--dogear-size)) 0%, 100% var(--dogear-size), 100% 100%);
}

.dogear-lg {
  --dogear-size: 45px !important;
}

/**
 * Callouts
 */
@include media-breakpoint-up(md) {
  .callout-image-container, .recent-illusion-container {

    &:last-of-type, &:first-of-type {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        z-index: 4;
        border-style: solid;
        border-width: 0 60px 60px 0;
        border-color: transparent #fff transparent transparent;
      }
    }
    &:first-of-type::after {
      bottom: 0;
      left: 0;
      transform: rotate(180deg);
    }
    &:last-of-type::after {
      top: 0;
      right: 0;
    }
  }
}

/**
 * Section: Featured Illusions
 */
.featured-illusions {
  // Desktop split background image / white bar
  @include media-breakpoint-up(md) {
    background-image: url('https://a.storyblok.com/f/253271/2000x579/f63fc4f452/home-banner-bg.jpg');
    background-size: cover;
    background-position: center;
    min-height: 400px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 50px;
      background: white;
      height: 100px;
      width: 100%;
      z-index: 1;
    }
  }

  // Mobile background image
  .feature-illusions-intro {
    @include media-breakpoint-down(md) {
      background-image: url('https://a.storyblok.com/f/253271/2000x579/f63fc4f452/home-banner-bg.jpg');
      background-size: cover;
      background-position: center;
    }
  }


  // Hide 5th tile on mobile
  @include media-breakpoint-down(md) {
    .featured-illusion-tile[data-index="5"] {
      display: none;
    }
  }

  // Common background image settings for the featured illusion images
  .featured-illusion-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height:200px;
  }

  // clip-path makes borders tricky - create a container with a background that
  // acts as the border, and clip slightly larger than the contents
  .featured-illusions-row-container {
    --dogear-size: 47px;
    padding: 2px;
    clip-path: polygon(var(--dogear-size) 100%, 0% calc(100% - var(--dogear-size)), 0% 0%, calc(100% - var(--dogear-size)) 0%, 100% var(--dogear-size), 100% 100%);
  }
}

/**
 * Section: Recent Illusions
 */
 .recent-illusions {
  // Common background image settings for the recent illusion images
  .recent-illusion-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;

    @include media-breakpoint-down(md) {
      border: 2px solid $secondary;
      height: 200px;
    }
  }

  // clip-path makes borders tricky - create a container with a background that
  // acts as the border, and clip slightly larger than the contents
  .recent-illusions-row-container {
    @include media-breakpoint-up(md) {
      --dogear-size: 47px;
      padding: 2px;
      clip-path: polygon(var(--dogear-size) 100%, 0% calc(100% - var(--dogear-size)), 0% 0%, calc(100% - var(--dogear-size)) 0%, 100% var(--dogear-size), 100% 100%);
      background-color: $secondary;
    }
  }

  .recent-illusions-row {
    @include media-breakpoint-down(md) {
      --dogear-size: 0 !important;
    }
  }

  .recent-illusions-callout > .callout-image > div {
    height: 100%;
  }
}

/**
 * Illusion
 */
.rollout-container {
  .rollout-before, .rollout-after {
    transition: opacity 0.3s;
  }
  .rollout-before {
    opacity: 1;
  }
  .rollout-after {
    opacity: 0;

    img {
      max-height: 100%;
      vertical-align: middle;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    .rollout-before {
      opacity: 0
    }
    .rollout-after {
      opacity: 1;
    }
  }
}

// Fix overlay-tall image height
#illusion-image {
  max-height: 600px;
}

#illusion-richtext-content img {
  display: block;
  max-width: 80%;
  margin: 10px auto;
}

#illusion-richtext-content iframe[src*="youtube"] {
  display: block;
  max-width: 80%;
  margin: 10px auto;
}

// #illusion-richtext-content *:has(img) {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   max-width: 80%;
//   margin: 0 auto;

//   > img {
//     flex-grow: 1;
//     flex-basis: 0;
//     min-width: 0;
//   }
// }

/**
 * Utility
 */
img {
  max-width: 100%;
}

/**
  * Tags
*/
.tags {
  a {
    span {
      &:before, &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        z-index: 1;
      }
      &:before {
        bottom: -1px;
        left: -1px;
        border-width: 10px 0 0 10px;
        border-color: transparent transparent transparent #fff;
      }
      &:after {
        top: -1px;
        right: -1px;
        border-width: 0 10px 10px 0;
        border-color: transparent #fff transparent transparent;
      }
    }
  }
}

/**
 * Carousel nav
 */
.carousel-control-prev, .carousel-control-next {
  bottom: auto !important;
  width: auto !important;
  opacity: 1;
  @extend .px-1;
  @extend .py-3;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  position: relative;
}
.carousel-control-prev-icon {
  left: -2px;
}
.carousel-control-next-icon {
  right: -2px;
}

.center-caption {
  margin: 0 auto;
}
