/**
 * mixins.scss
 * =============================================================================
 */

 @mixin fixed-width-containers() {
  @each $key, $value in $container-max-widths {
      .container-#{$key}-fixed {
          max-width: $value !important;
      }
  }
}
@include fixed-width-containers();
